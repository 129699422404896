<template>
   <div class="notice_popup cp_prj">
        <div class="close">
            <img src="/images/close.png" alt="닫기" @click="$emit('close', false)"/>
        </div>
        <div class="name">
            {{data.mberNm}}
            <span>({{data.gender | gender}} / {{data.birthYear | birthYear}})</span>
        </div>
        <div class="mem_num">
            <img src="/images/tec/myp/logo_sm.png" alt="하이프로로고"/> 
        </div>
        <div class="tit">완료프로젝트</div>
        <div class="scroll-box cp_prj">
            <div class="list-box" v-for="item in data.list" :key="item.projectSeq + '_' + item.evalTabSeq">
                <div class="prj_outline">
                    <div class="left">
                        <div class="regi mr-5">
                            <!-- <div class="outline"> -->
                            <div class="outline" style="display: inline-flex;justify-content: center;align-items: center;">
                                <div class="label ivl10">프로젝트명</div><div class="value prj" style="width: 460px;">{{item.projectNm}}</div>
                            </div>
                            <div class="outline">
                                <div class="label ivl03" style="margin-right: -3px;">개요</div><div class="value text">{{item.projectDtlDesc}}</div>
                            </div>
                            <div class="outline">
                                <div class="label ivl02">고 객 사</div>
                                <div style="display: inline-flex; width: 460px;">
                                    <div class="value" style="display: inline-block; white-space: nowrap;">{{item.clientNm}}</div>
                                    <template v-if="item.projectLocCont">
                                        <img src="/images/location.png" alt="위치"/><div class="value location">{{item.projectLocCont}}</div>
                                    </template>
                                </div>
                            </div>
                            <div class="outline" style="display: inline-flex;">
                                <div class="label ivl05">산업분야</div>
                                <!-- <div class="value">{{item.corpMgrMberNm}}</div> -->
                                <div class="value" style="width: 460px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;"><SelectComp type="text" cdId="PRO105" :value="item.indtypeFilterCd"/> / <SelectComp type="text" cdId="PRO143" :value="item.indtypeClassCd"/></div>
                            </div>                             

                        </div>
                        <div class="int_box">
                            <div class="outline">
                                <div class="label ivl02">수 행 사</div><div class="value">{{item.corpNm}}</div>
                            </div>
                            <div class="outline">
                                <div class="label ivl02">담 당 자</div><div class="value">{{item.corpMgrMberNm}}</div>
                            </div>
                            <div class="outline">
                                <div class="label">개 발 기 간</div><div class="value">{{item.joinStartYyyymmdd | date('yyyy.MM.dd')}} ~ {{item.joinEndYyyymmdd | date('yyyy.MM.dd')}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="right">
                        <p>수행사 평가</p>
                        <div class="pt-12" v-if="!item.itemDivGrade">평가 결과가 없습니다.</div>
                        <div v-else class="star-evl" v-for="(str, idx) in item.itemDivGrade.split('|')" :key="idx">
                            <grade-img type="CLIENT_EVAL_CST" :grade="str.split(':')[1]"/>
                            <SelectComp tagName="p" type="text" :value="str.split(':')[0]" cdId="PRO110"/>
                        </div>
                    </div>
                </div>
                <!-- 프로젝트 평가 -->
                <div class="review" v-if="item.evaluatorLoginId">
                    <div class="layout">
                        <div class="photo">
                            <img src="/images/cstom_photo.png" alt="사진"/>
                        </div>
                        <div class="cstom_name">수행사<span>{{item.evaluatorLoginId}}</span></div>
                    </div>
                    <div class="layout">
                        <div class="list cstom eval">
                            <p>프로젝트 평가<span class="date">{{item.evalYyyymmdd | date('yyyy.MM.dd')}}</span></p>
                            <nbsp tagName="span" :value="item.overallOpinCont"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>

<script>
import gradeImg from "@/components/highpro/GradeImg.vue";
export default {
	components: { gradeImg },
    props: ['param'],
    data() {
        return {
            data: {},
        };
    }, 
    mounted(){
        //console.log('PRF301P06', 'mounted', this.param);
        if(this.param.tecMberSeq){
            this.$.httpPost('/api/mem/prf/getTecEval', {tecMberSeq: this.param.tecMberSeq, evalYn:'N'})
                .then(res => {
                    this.data = res.data;
                });
        } else {
            alert('파라미터를 확인해 주세요.');
            this.$emit('close', false);
        }
    },
    methods: {
    },
};
</script>